import { type Components } from "@mui/material/styles";

export const muiAccordionOverrides: Components = {
  MuiAccordion: {
    defaultProps: {
      square: true,
    },
    styleOverrides: {
      root: {
        "&, &.Mui-disabled": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      content: {
        alignItems: "center",

        // Icon placed before the accordion summary text
        "& > svg:first-child": {
          marginRight: "0.5rem",
        },
      },
    },
  },
};
