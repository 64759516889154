import { type Components } from "@mui/material/styles";

import { getSafeAreaPaddings } from "../../utils/getSafeAreaPaddings";
import { BACKGROUND_TERTIARY_COLOR, PRIMARY_COLOR, TEXT_INVERT_PRIMARY_COLOR } from "../colors";
import { BORDER_RADIUS_SMALL, ELEVATION_4 } from "../common";

export const muiBottomNavigationOverrides: Components = {
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        marginTop: "auto",
        paddingTop: "16px",
        ...getSafeAreaPaddings({
          bottom: true,
          left: true,
          right: true,
          fallbackBottom: "16px",
          fallbackLeft: "24px",
          fallbackRight: "24px",
        }),
        justifyContent: "space-between",
        backgroundColor: BACKGROUND_TERTIARY_COLOR,
        // override MUI's default height
        height: "fit-content",
        boxShadow: ELEVATION_4,
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        width: "48px",
        maxWidth: "48px",
        minWidth: "unset",
        height: "48px",
        maxHeight: "48px",
        minHeight: "unset",
        borderRadius: BORDER_RADIUS_SMALL,

        "&.Mui-selected": {
          color: TEXT_INVERT_PRIMARY_COLOR,
          backgroundColor: PRIMARY_COLOR,
        },

        "& svg": {
          height: "28px",
          width: "28px",
        },
      },
    },
  },
};
