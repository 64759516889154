import { type Components, type Theme } from "@mui/material/styles";

import { muiAccordionOverrides } from "./overrides/accordion";
import { muiAvatarOverrides } from "./overrides/avatar";
import { muiBadgeOverrides } from "./overrides/badge";
import { muiBottomNavigationOverrides } from "./overrides/bottomNavigation";
import { muiButtonOverrides } from "./overrides/button";
import { muiCardOverrides } from "./overrides/card";
import { muiChipOverrides } from "./overrides/chip";
import { muiDialogOverrides } from "./overrides/dialog";
import { muiDividerOverrides } from "./overrides/divider";
import { muiDrawerOverrides } from "./overrides/drawer";
import { muiIconButtonOverrides } from "./overrides/iconButton";
import { muiInputOverrides } from "./overrides/input";
import { muiListOverrides } from "./overrides/list";
import { muiPaperOverrides } from "./overrides/paper";
import { muiRatingOverrides } from "./overrides/rating";
import { muiSliderOverrides } from "./overrides/slider";
import { muiTabsOverrides } from "./overrides/tabs";
import { muiToggleButtonOverrides } from "./overrides/toggleButton";

export const ICON_SIZE_EXTRA_SMALL = "0.875rem";
export const ICON_SIZE_SMALL = "1rem";
export const ICON_SIZE_MEDIUM = "1.5rem";
export const ICON_SIZE_LARGE = "2rem";
export const ICON_SIZE_EXTRA_LARGE = "3.5rem";

export const DATE_CELL_SMALL_WIDTH = "2.875rem"; // 46px
export const DATE_CELL_SMALL_HEIGHT = "4rem"; // 64px
export const DATE_CELL_MEDIUM_WIDTH = "4rem"; // 64px
export const DATE_CELL_MEDIUM_HEIGHT = "4.875rem"; // 78px

export const muiThemeComponents: Components<Theme> = {
  ...muiAccordionOverrides,
  ...muiAvatarOverrides,
  ...muiBadgeOverrides,
  ...muiBottomNavigationOverrides,
  ...muiButtonOverrides,
  ...muiCardOverrides,
  ...muiChipOverrides,
  ...muiDialogOverrides,
  ...muiDividerOverrides,
  ...muiDrawerOverrides,
  ...muiIconButtonOverrides,
  ...muiInputOverrides,
  ...muiListOverrides,
  ...muiPaperOverrides,
  ...muiRatingOverrides,
  ...muiSliderOverrides,
  ...muiTabsOverrides,
  ...muiToggleButtonOverrides,
};
