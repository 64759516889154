import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_PRIMARY_COLOR,
  DISABLED_PRIMARY_COLOR,
  SURFACE_SHADED_DARK_COLOR,
} from "../colors";

export const AVATAR_SIZE_SMALL = "2rem";
export const AVATAR_SIZE_MEDIUM = "4rem";
export const AVATAR_SIZE_LARGE = "6.5rem";

export const AVATAR_SIZES = ["small", "medium", "large"] as const;
export type AvatarSize = (typeof AVATAR_SIZES)[number];

declare module "@mui/material/Avatar" {
  interface AvatarOwnProps {
    size?: AvatarSize;
  }
}

export const muiAvatarOverrides: Components = {
  MuiAvatar: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      root: {
        backgroundColor: BACKGROUND_PRIMARY_COLOR,

        variants: [
          {
            props: { size: "small" },
            style: {
              width: AVATAR_SIZE_SMALL,
              height: AVATAR_SIZE_SMALL,
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: AVATAR_SIZE_MEDIUM,
              height: AVATAR_SIZE_MEDIUM,
            },
          },
          {
            props: { size: "large" },
            style: {
              width: AVATAR_SIZE_LARGE,
              height: AVATAR_SIZE_LARGE,
            },
          },
        ],
      },

      // Displayed when there is no image
      colorDefault: {
        backgroundColor: SURFACE_SHADED_DARK_COLOR,
        color: DISABLED_PRIMARY_COLOR,
      },
    },
  },
};
