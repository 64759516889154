export const ELEVATION_1 = "0px 0px 1px 0px rgba(0, 0, 0, 0.2)";
export const ELEVATION_2 = "0px 4px 24px 0px rgba(0, 0, 0, 0.04)";
export const ELEVATION_3 = "0px 4px 40px 0px rgba(0, 0, 0, 0.15)";
export const ELEVATION_4 = "0px -10px 20px 0px rgba(0, 0, 0, 0.05)";

export const ELEVATIONS = [0, 1, 2, 3, 4] as const;
export type Elevation = (typeof ELEVATIONS)[number];

export const BORDER_RADIUS_SMALL = "16px";
export const BORDER_RADIUS_MEDIUM = "24px";
export const BORDER_RADIUS_LARGE = "32px";
export const BORDER_RADIUS_XLARGE = "100px";

export const BORDER_WIDTH_THIN = "0.5px";
export const BORDER_WIDTH_REGULAR = "1px";
export const BORDER_WIDTH_THICK = "2px";

export const SPACINGS = [0, 4, 6, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64];
