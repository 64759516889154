import { type Components } from "@mui/material/styles";

import { UNREAD_CHAT_NOTIFICATION_BADGE_COLOR } from "../colors";
import { BODY_2_FONT_SIZE, CAPTION_FONT_SIZE } from "../typography";

export const BADGE_SIZES = ["small", "medium"] as const;
export type BadgeSize = (typeof BADGE_SIZES)[number];

declare module "@mui/material/Badge" {
  interface BadgeOwnProps {
    size?: BadgeSize;
  }
}

export const muiBadgeOverrides: Components = {
  MuiBadge: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      colorInfo: UNREAD_CHAT_NOTIFICATION_BADGE_COLOR,

      badge: {
        fontSize: BODY_2_FONT_SIZE,
        variants: [
          {
            props: { size: "small", variant: "standard" },
            style: {
              minWidth: "16px",
              height: "16px",
              padding: "0 4px",
              fontSize: CAPTION_FONT_SIZE,
            },
          },
        ],
      },
    },
  },
};
