import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_PRIMARY_COLOR,
  BACKGROUND_TERTIARY_COLOR,
  BORDER_DARK_COLOR,
  BORDER_DARK_INVERT_COLOR,
  BORDER_DESTRUCTIVE_COLOR,
  BORDER_PRIMARY_COLOR,
  BORDER_PRIMARY_INVERT_COLOR,
  BORDER_SUBTLE_INVERT_COLOR,
  DISABLED_SECONDARY_COLOR,
  DISABLED_TERTIARY_COLOR,
  ICON_DISABLED_COLOR,
  ICON_INVERT_DARK_COLOR,
  PRIMARY_COLOR,
  PRIMARY_DARK_COLOR,
  TEXT_DESTRUCTIVE_COLOR,
  TEXT_DISABLED_COLOR,
  TEXT_INVERT_DESTRUCTIVE_COLOR,
  TEXT_INVERT_PRIMARY_COLOR,
  TEXT_INVERT_TERTIARY_COLOR,
  TEXT_PRIMARY_COLOR,
} from "../colors";
import { BORDER_RADIUS_XLARGE } from "../common";
import {
  BODY_1_FONT_SIZE,
  BODY_1_LETTER_SPACING,
  BODY_1_LINE_HEIGHT,
  BODY_2_FONT_SIZE,
  BODY_2_LETTER_SPACING,
  BODY_2_LINE_HEIGHT,
} from "../typography";

export const BUTTON_SIZE_SMALL = "2.5rem"; // 40px
export const BUTTON_SIZE_MEDIUM = "3rem"; // 48px
export const BUTTON_SIZE_LARGE = "4rem"; // 64px

export const BUTTON_VARIANTS = ["contained", "outlined", "destructive"] as const;
export const BUTTON_SIZES = ["small", "medium", "large"] as const;

export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];
export type ButtonSize = (typeof BUTTON_SIZES)[number];

declare module "@mui/material/Button" {
  interface ButtonOwnProps {
    /**
     * Inverts the button colors.
     */
    invert?: boolean;
  }
}

/**
 * Use Button from src/appV2/ShiftDiscovery/components/Button.tsx
 * instead of MUI Button.
 */
export const muiButtonOverrides: Components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      draggable: false,
    },
    styleOverrides: {
      root: {
        borderRadius: 100,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "unset",
        lineHeight: "1.5rem",
        borderRadius: BORDER_RADIUS_XLARGE,
        fontWeight: 500,

        // These icons have negative margin by default,
        // we need to override it.
        "& .MuiButton-startIcon": {
          marginLeft: 0,
        },

        "& .MuiButton-endIcon": {
          marginRight: 0,
        },

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: "1.25rem", // 20px
          height: "1.25rem", // 20px
        },

        "&.Mui-disabled": {
          color: TEXT_DISABLED_COLOR,

          "& svg": {
            color: ICON_DISABLED_COLOR,
          },
        },
      },
      sizeSmall: {
        fontSize: BODY_2_FONT_SIZE,
        fontWeight: 500,
        lineHeight: BODY_2_LINE_HEIGHT,
        letterSpacing: BODY_2_LETTER_SPACING,
        padding: "0.5rem 1.25rem", // 8px 20px
        height: BUTTON_SIZE_SMALL,

        "& svg": {
          width: "1rem", // 16px
          height: "1rem", // 16px
        },
      },
      sizeMedium: {
        padding: "0.75rem 1.25rem", // 12px 20px
        fontSize: BODY_1_FONT_SIZE,
        lineHeight: BODY_1_LINE_HEIGHT,
        letterSpacing: BODY_1_LETTER_SPACING,
        height: BUTTON_SIZE_MEDIUM,
      },
      sizeLarge: {
        padding: "1.25rem 1.5rem", // 20px 24px
        fontSize: BODY_1_FONT_SIZE,
        lineHeight: BODY_1_LINE_HEIGHT,
        letterSpacing: BODY_1_LETTER_SPACING,
        height: BUTTON_SIZE_LARGE,
      },
      contained: {
        "@media (hover: hover)": {
          "&:hover, &:focus": {
            backgroundColor: PRIMARY_DARK_COLOR,
          },
        },

        "@media (hover: none)": {
          "&:active": {
            backgroundColor: PRIMARY_DARK_COLOR,
          },
        },

        "&.Mui-disabled": {
          backgroundColor: DISABLED_TERTIARY_COLOR,
        },

        variants: [
          {
            props: { invert: true },
            style: {
              color: TEXT_PRIMARY_COLOR,
              backgroundColor: BACKGROUND_TERTIARY_COLOR,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  backgroundColor: BACKGROUND_PRIMARY_COLOR,
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  backgroundColor: BACKGROUND_TERTIARY_COLOR,
                },

                "&:active": {
                  backgroundColor: BACKGROUND_PRIMARY_COLOR,
                },
              },
            },
          },
        ],
      },
      outlined: {
        border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        backgroundColor: "transparent",

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${BORDER_DARK_COLOR}`,
            // Override default hover background color.
            backgroundColor: "transparent",
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile to look like default styles.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            border: `1px solid ${BORDER_PRIMARY_COLOR}`,
          },

          "&:active": {
            border: `1px solid ${BORDER_DARK_COLOR}`,
          },
        },

        "&.Mui-disabled": {
          border: `1px solid ${DISABLED_SECONDARY_COLOR}`,
        },

        variants: [
          {
            props: { invert: true },
            style: {
              color: TEXT_INVERT_PRIMARY_COLOR,
              border: `1px solid ${BORDER_PRIMARY_INVERT_COLOR}`,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  border: `1px solid ${BORDER_DARK_INVERT_COLOR}`,
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  border: `1px solid ${BORDER_PRIMARY_INVERT_COLOR}`,
                },

                "&:active": {
                  border: `1px solid ${BORDER_DARK_INVERT_COLOR}`,
                },
              },

              "&.Mui-disabled": {
                color: TEXT_INVERT_TERTIARY_COLOR,
                border: `1px solid ${BORDER_SUBTLE_INVERT_COLOR}`,

                "& svg": {
                  color: ICON_INVERT_DARK_COLOR,
                },
              },
            },
          },
        ],
      },
      outlinedSecondary: {
        color: PRIMARY_COLOR,

        "&.Mui-disabled": {
          border: `1px solid ${DISABLED_SECONDARY_COLOR}`,
        },
      },

      // Used by our custom destructive buttons
      outlinedError: {
        color: TEXT_DESTRUCTIVE_COLOR,

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${BORDER_DESTRUCTIVE_COLOR}`,
          },
        },

        "@media (hover: none)": {
          "&:active": {
            border: `1px solid ${BORDER_DESTRUCTIVE_COLOR}`,
          },
        },

        variants: [
          {
            props: { invert: true },
            style: {
              color: TEXT_INVERT_DESTRUCTIVE_COLOR,
            },
          },
        ],
      },
    },
  },
};
